export default class Video {
  readonly slug: string;

  readonly title: string;

  readonly video?: string | File;

  constructor(
    slug: string,
    title: string,
    video?: string,
  ) {
    this.slug = slug;
    this.title = title;
    this.video = video;
  }
}
