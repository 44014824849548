import styled from 'styled-components';

export const ProjectsStyles = styled.div`
  max-width: 647px;
  width: 100%;
  margin: auto;
  box-sizing: border-box;
  padding: 69px 10px 0;
  
  h2 {
    color: #ffd800;
    font-family: "Myriad Pro", sans-serif;
    font-size: 31px;
    font-weight: 700;
    line-height: 16px;
    margin-bottom: 21px;
  }
  
  .photoWrapper {
    max-width: 608px;
    width: 100%;
    margin-bottom: 7px;
    cursor: pointer;
    
    img {
      width: 100%;
    }
  }
`;
