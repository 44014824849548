import instagram from './assets/icons/instagram.png';
import facebook from './assets/icons/facebook.png';
import youtube from './assets/icons/youtube.png';
import telegram from './assets/icons/telegram.png';
import twitter from './assets/icons/twitter.png';
import uah from './assets/icons/uah.png';
import euro from './assets/icons/euro.png';
import dollar from './assets/icons/dollar.png';
// @ts-ignore
import video from './assets/video.mp4';

export const currencyIcons: { [key: string]: string } = {
  usd: dollar,
  eur: euro,
  uah: uah,
}

export const headerLinks = [
  {
    text: 'AVILOVA.FUND',
    link: 'https://www.instagram.com/avilova.fund',
    icon: instagram,
    alt: 'insagram icon',
  },
  {
    text: 'AVILOVA FUND',
    link: 'https://www.facebook.com/profile.php?id=100081169353521',
    icon: facebook,
    alt: 'facebook icon',
  },
  {
    text: 'AVILOVAFUND',
    link: 'https://www.youtube.com/channel/UCPzvhKqRjENEYloGfDV_sQA',
    icon: youtube,
    alt: 'youtube icon',
  },
  {
    text: 'AVILOVAFUND',
    link: 'https://t.me/AvilovaFund',
    icon: telegram,
    alt: 'telegram icon',
  },
  {
    text: 'AVILOVAFUND',
    link: 'https://www.twitter.com/avilovafund',
    icon: twitter,
    alt: 'twitter icon',
  }
]

export const translations: {[index:string]:any} = {
  en : {
    header_text: 'assistance to people affected by the war in Ukraine',

    requisites_title: 'REQUISITES',

    requisites_card_title: 'Individual card:',
    requisites_goal: 'уindicate the assigned payments "for charity"',
    requisites_card_uahName: 'TIMOSHENKO SERGEJ',
    requisites_identNumb: 'TIN: ',
    requisites_nameDollar: 'TIMOSHENKO SERGEJ',

    requisites_charitable_title: 'Charitable Fund Account:',
    requisites_code: 'Recipient code : ',
    requisites_bo: 'CO SC FUND',
    requisites_iban: 'IBAN: ',
    requisites_swift: 'SWIFT: ',
    requisites_purpose: 'Purpose of payment: ',
    requisites_purpose2: ' For charity',

    projects_title: 'OUR PROJECTS',

    aboutus_title: 'ABOUT US',

    contacts_workingDays: 'Mon-Fri',
    contacts_address: 'Chernihiv, 11 Kyivska Street',

    oneProject_title: 'Project',
    oneProject_do: 'Done: ',
    oneProject_get: 'Collected: ',

    footer_text: 'Charitable Organization «AvilovaFund», 2022',

    title_copied: 'Copy',
    title_goToProject: 'Go to the detailed project description',
    title_call: 'Call to fund',
    title_email: 'Send email',
    title_language: 'Change language',
    title_fullScreen: 'Open full screen',
    title_menuButton: 'Menu'
  },

  uk: {
    header_text: 'допомога людям, які постраждали від війни в Україні',

    requisites_title: 'РЕКВІЗИТИ',

    requisites_card_title: 'Картка фізичної особи:',
    requisites_goal: 'у призначенні платежу вказуйте «на благодійність»',
    requisites_card_uahName: 'Тимошенко Сергій Миколайович',
    requisites_identNumb: 'РНУКПН: ',
    requisites_nameDollar: 'TIMOSHENKO SERGEJ',

    requisites_charitable_title: 'Рахунок Благодійного фонду:',
    requisites_code: 'Код отримувача: ',
    requisites_bo: 'БО ОК ФОНД',
    requisites_iban: 'IBAN: ',
    requisites_swift: 'SWIFT: ',
    requisites_purpose: 'Призначення платежу: ',
    requisites_purpose2: 'На благодійність',

    projects_title: 'НАШІ ПРОЕКТИ',

    aboutus_title: 'ПРО НАС',

    contacts_workingDays: 'Пн-Пт',
    contacts_address: ' м. Чернігів, вул.Київська 11',

    oneProject_title: 'ПРОЕКТ',
    oneProject_do: 'Зроблено: ',
    oneProject_get: 'Зібрано: ',

    footer_text: 'Благодійна організація «AvilovaFund», 2022',

    title_copied: 'Скопіювати',
    title_goToProject: 'Перейти до розгорнутого опису проекту',
    title_call: 'Подзвонити до фонду',
    title_email: 'Надіслати електронного листа',
    title_language: 'Змінити мову',
    title_fullScreen: 'Відкрити на весь екран',
    title_menuButton: 'Меню'
  }
}

export const navItems: {[index:string]: {[index:string]:any}[]}  = {
  uk: [
    {
      text: 'Головна',
      href: '#main'
    },
    {
      text: 'Реквізити',
      href: '#requisites'
    },
    {
      text: 'Наші проекти',
      href: '#projects'
    },
    {
      text: 'Про нас',
      href: '#aboutus'
    },
    {
      text: 'Контакти',
      href: '#contacts'
    },
  ],
  en: [
    {
      text: 'Main',
      href: '#main'
    },
    {
      text: 'Requisites',
      href: '#requisites'
    },
    {
      text: 'Our projects',
      href: '#projects'
    },
    {
      text: 'About us',
      href: '#aboutus'
    },
    {
      text: 'Contacts',
      href: '#contacts'
    },
  ]
}

export const requisites_card: {[index:string]: {[index:string]:any}}[]  = [
  {
    en: {
      bankCode: '40298333',
      name: 'TIMOSHENKO SERGEJ',
      iban: 'UA833052990000026208735956246',
      cardNumber: '5168-7427-3187-1228',
      in: '3122409670',
      icon: uah,
      alt: 'hryvnia'
    },

    uk: {
      bankCode: '40298333',
      name: 'ТИМОШЕНКО СЕРГІЙ МИКОЛАЙОВИЧ',
      in: '3122409670',
      iban: 'UA833052990000026208735956246',
      cardNumber: '5168-7427-3187-1228',
      icon: uah,
      alt: 'Гривня'
    },
  },
  {
    en: {
      iban: 'UA503052990262076400933795943',
      name: 'TIMOSHENKO SERGEJ',
      cardNumber: '5168-7450-2206-4734',
      icon: euro,
      alt: 'euro'
    },
    uk: {
      iban: 'UA503052990262076400933795943',
      name: 'TIMOSHENKO SERGEJ',
      cardNumber: '5168-7450-2206-4734',
      icon: euro,
      alt: 'euro'
    },
  },
]

export const requisites_fond: {[index:string]: {[index:string]:any}}[] = [
  {
    en: {
      bankCode: '44784962',
      name: 'CO ОК Fund',
      iban: 'UA943510050000026006879145263',
      icon: uah,
      alt: 'hryvnia'
    },

    uk: {
      bankCode: '44784962',
      name: 'БО ОК ФОНД',
      iban: 'UA943510050000026006879145263',
      icon: uah,
      alt: 'hryvnia'
    },
  },
  {
    en: {
      bankCode: '44784962',
      iban: 'UA513510050000026003879145266',
      name: 'CO ОК Fund',
      swift: 'KHABUA2K',
      icon: euro,
      alt: 'euro'
    },
    uk: {
      bankCode: '44784962',
      iban: 'UA513510050000026003879145266',
      name: 'БО ОК ФОНД',
      swift: 'KHABUA2K',
      icon: euro,
      alt: 'euro'
    },
  },
  {
    en: {
      bankCode: '44784962',
      iban: 'UA69351005000002600287914267',
      name: 'CO ОК Fund',
      swift: 'KHABUA2K',
      icon: dollar,
      alt: 'dollar'
    },
    uk: {
      bankCode: '44784962',
      iban: 'UA69351005000002600287914267',
      name: 'БО ОК ФОНД',
      swift: 'KHABUA2K',
      icon: dollar,
      alt: 'dollar'
    },
  },
]

export const metaTitle: {[index: string]: string} = {
  en: 'Charitable organization  "Avilova Fund"',
  uk: 'Благодійна організація "Avilova Fund"',
}

export const metaDescription: {[index: string]: string} = {
  en: 'Assistance to people affected by the war in Ukraine',
  uk: 'Допомога людям, які постраждали від війни в Україні',
}
