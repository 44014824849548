import SanityClientConfiguration from './sanity/SanityClientConfiguration';

const production: SanityClientConfiguration = {
  projectId: 't70izu4i',
  dataset: 'production',
  useCdn: true,
};

const config = {
  production,
};

export {config as SanityClientConfiguration};
