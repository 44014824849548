import styled from 'styled-components';

export const SelectedProjectStyles = styled.div`
  max-width: 647px;
  width: 100%;
  box-sizing: border-box;
  margin: auto auto 57px;

  .projectContainer {
    padding: 69px 10px 0;

  }

  h2 {
    margin-bottom: 21px;
    color: #ffd800;
    font-family: "Myriad Pro", sans-serif;
    font-size: 31px;
    font-weight: 700;
    line-height: 16px;
  }

  .mainPhotoWrapper {
    margin-bottom: 20px;
    max-width: 627px;
    width: 100%;

    img {
      width: 100%;
    }
  }
  
  .collected {
    font-family: "Myriad Pro", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: #ff0000;
    margin-bottom: 11px;

    .big {
      font-weight: 700;
    }
  }
  
  .completedText {
    display: flex;
    gap: 10px;
    color: #4c5d56;
    font-family: "Myriad Pro", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;
    margin-bottom: 60px;
  }
  
  .projectText {
    color: #a4a1a1;
    font-family: "Myriad Pro - It", sans-serif;
    font-size: 14px;
    letter-spacing: 0.35px;
    margin-bottom: 27px;
  }
  
  .gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    gap: 10px;
    
    .photoContainer {
      max-width: 186px;
      height: 116px;
      width: 100%;
      cursor: pointer;

      @media (max-width: 598px) {
        max-width: 400px;
        height: 200px;
      }
      
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  
  .modal {
    
    .modalPhotoContainer {
      width: 100%;
      
      img {
        width: 100%;
      }
    }
    
    button {
      position: fixed;
      top: 10px;
      right: 10px;
      background: none;
      color: #fff;
      font-size: 30px;
    }
  }
  
  .videoWrapper {
    max-width: 284px;
    height: 254px;
    width: 100%;
    
    video {
      object-fit: cover;
    }
    
    @media (max-width: 598px) {
      max-width: 400px;
    }
  }
  
`;
