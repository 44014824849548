import React, {useContext, useEffect, useRef, useState} from 'react';
import {SelectedProjectStyles} from './SelectedProjectStyles';
import Modal from "../modal/Modal";
import ReactPlayer from "react-player";
// @ts-ignore
import * as Scroll from 'react-scroll';
import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

import {useParams} from "react-router-dom";
import Post from "../../entities/Post";
import {Api} from "../../api";
import {PortableText} from "@portabletext/react";
import Picture from "../../entities/Picture";
import Video from "../../entities/Video";
import {LangContext} from "../../context/LangContext/LangContext";
import {translations} from "../../constants";
import Contacts from "../contacts/Contacts";

function SelectedProject() {
  const [modal, setModal] = useState<boolean>(false);
  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState<number | null>(null);
  const [project, setProject] = useState<Post>();

  const { locale, setLocale } = useContext(LangContext);

  const myRef = useRef<any>(null)

  const { slug } = useParams();

  const handleClose = () => {
    setSelectedPhotoIndex(null);
    setModal(false);
  }

  useEffect(() => {
    // window.scroll(0, myRef.current.offsetTop)
    if (slug) {
      Api.getPostByKey(slug)
        .then((res) => {
          setProject(res)
        })
    }
  }, [])

  useEffect(() => {
    scroller.scrollTo('projectContainer', {
      duration: 0,
      delay: 0,
      smooth: 'auto'
    })
    // myRef.current.scrollIntoView({ block: 'start', behavior: 'auto' })
  }, [project])

  return (
    <>
      <SelectedProjectStyles>
        { modal && (
          <Modal onClose={() => setModal(false)}>
            <button type="button" onClick={() => setModal(false)}>X</button>
            {
              (selectedPhotoIndex || selectedPhotoIndex === 0) &&
              ( <div className="modalPhotoContainer">
                  { project && (
                    // @ts-ignore
                    <img src={project.gallery[selectedPhotoIndex].picture} alt="photo"/>
                  )}
                </div>
              )
            }
          </Modal>
        )
        }
        { project &&
          <Element name="projectContainer" className="projectContainer">
            <h2>{translations[locale].oneProject_title}</h2>
            <div>
              <div className="mainPhotoWrapper">
                <img src={project.mainImage[locale] ? project.mainImage[locale] : project.mainImage.en} alt="project main photo"/>
              </div>

              <p className="projectText">
                {project.body && <PortableText value={project.body[locale] ? project.body[locale] : project.body.en} />}
              </p>

              <div className="collected">

                { project.collected &&
                  <>
                    <span>{translations[locale].oneProject_get} </span>
                    <span className="big">{project.collected[locale] ? project.collected[locale] : project.collected.en}</span>
                  </>
                }
              </div>
              <div className="completedText">
                {project.done &&
                  <>
                    <span>{translations[locale].oneProject_do}</span>
                    <PortableText value={project.done[locale] ? project.done[locale] : project.done.en} />
                  </>
                }
              </div>
              { (project && project.gallery && project.gallery?.length > 0) &&
                <div className="gallery">
                  {project.gallery && project.gallery.length && project.gallery.map((item, index) => {
                    if (!(item instanceof Picture) && item.video! && typeof item.video === 'string') {
                      return (
                        <div className="videoWrapper" key={index}>
                          <ReactPlayer url={item.video} controls width='100%' height="100%"/>
                        </div>
                      )
                    }

                    if (!(item instanceof Video) && item.picture) {
                      return (
                        <div title={translations[locale].title_fullScreen} key={index} className="photoContainer"
                             onClick={() => {
                               setSelectedPhotoIndex(index);
                               setModal(true);
                             }}>
                          <img src={item.picture} alt="photo"/>
                        </div>
                      )
                    }
                  })}
                </div>
              }
            </div>
          </Element>
        }
      </SelectedProjectStyles>
      <Contacts />
    </>
  );
}

export default SelectedProject;