import {ImageObject, TrackObject} from './CommonObjects';
import SanityClient from '../sanity/SanityClient';
import Post from '../../entities/Post';
import ApiPost from './Post';
import ApiAboutUs from './AboutUs';
import AboutUs from "../../entities/AboutUs";
import Picture from "../../entities/Picture";
import ApiCharitableCards from "./ApiCharitableCards";
import CharitableCards from "../../entities/CharitableCards";
import ApiIndividualCard from "./ApiIndividualCard";
import IndividualCard from "../../entities/IndividualCard";

export default class Mappers {
  constructor(private client: SanityClient) {}


  public mapFromApiPosts = (posts: ApiPost[]): Post[] =>
    posts.map(this.mapFromApiPost);

  public mapFromApiPost = (post: ApiPost): Post => {

    let result;
    if (post.gallery?.length) {
      result = post.gallery.map((item, index) => {
        if (!(item instanceof Picture) && item.video && typeof item.video === "object") {
          // @ts-ignore
          return {
            ...item,
            // @ts-ignore
            video: this.mapFromApiFile(item.video),
          }
        }

        return item
      })
    }

    return ({
      slug: post.slug,
      title: post.title,
      mainImage: post.mainImage,
      body: post.body,
      gallery: result || post.gallery,
      // @ts-ignore
      collected: post.collected,
      done: post.done
    })
  };


  public mapFromApiAboutUs = (stories: ApiAboutUs[]): AboutUs[] =>
    stories.map(this.mapFromApiStory);

  public mapFromApiStory = (story: ApiAboutUs): AboutUs => {
    return ({
      slug: story.slug,
      title: story.title,
      body: story.body,
    })
  };

  public mapFromApiContacts = (contacts: any): any =>
    contacts.map(this.mapFromApiContact);

  public mapFromApiContact = (contact: any): any => {
    return ({
      slug: contact.slug,
      title: contact.title,
      address: contact.address,
      email: contact.email,
      phone: contact.phone,
    })
  };

  public mapFromApiCharitableCards = (data: ApiCharitableCards[]): CharitableCards[] =>
    data.map(this.mapFromApiCharitableCard);

  public mapFromApiCharitableCard = (data: ApiCharitableCards): CharitableCards => {
    return ({
      iban: data.iban,
      swift: data.swift,
      currency: data.currency.toLowerCase(),
      recipientCode: data.recipientCode,
    })
  };

  public mapFromApiIndividualCards = (data: ApiIndividualCard[]): IndividualCard[] =>
    data.map(this.mapFromApiIndividualCard);

  public mapFromApiIndividualCard = (data: ApiIndividualCard): IndividualCard => {
    return ({
      iban: data.iban,
      cardNumber: data.cardNumber,
      tin: data.tin,
      name: data.name,
      currency: data.currency.toLowerCase(),
    })
  };

  // public mapFromApiPictures = (posts: ApiPicture[]): Picture[] =>
  //   posts.map(this.mapFromApiPicture);
  //
  // public mapFromApiPicture = (picture: ApiPicture): Picture => {
  //   console.log(picture)
  //   return ({
  //     slug: picture.slug,
  //     title: picture.title,
  //     image: this.mapFromApiImage(picture.picture, 50),
  //     text: picture.body,
  //   })
  // };



  private mapFromApiImage = (image: ImageObject, width?: number): string => {
    return this.client.urlForImage(image.asset._ref, 1040);
  };

  private mapFromApiFile = (file: TrackObject): string => {
    return this.client.urlForTrack(file.asset._ref);
  };

  private mapFromApiImages = (images: ImageObject[], width?: number): string[] => {
    return images.map((img) => this.mapFromApiImage(img, width));
  };

}
