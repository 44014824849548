import React from 'react';
import {LayoutStyles} from './LayoutStyles';
import Header from "../header/Header";
import Navigation from "../navigation/Navigation";
import Requisites from "../requisites/Requisites";
import Contacts from "../contacts/Contacts";
import {Outlet} from "react-router-dom";
import Footer from "../footer/Footer";

function Layout() {
  return (
    <LayoutStyles>
      <div className="layout">
        <div>
          <Header />
          <Navigation />
          <Requisites />
          <Outlet />
        </div>
        <Footer />
      </div>
    </LayoutStyles>
  );
}

export default Layout;