export default class Picture {
  readonly slug: string;

  readonly title: string;

  readonly picture?: string;

  constructor(
    slug: string,
    title: string,
    picture?: string,
  ) {
    this.slug = slug;
    this.title = title;
    this.picture = picture;
  }
}
