import React, {useContext} from 'react';
import {FooterStyles} from './FooterStyles';
import {LangContext} from "../../context/LangContext/LangContext";
import {translations} from "../../constants";

function Footer() {
  const { locale, setLocale } = useContext(LangContext);

  return (
    <FooterStyles>
      <span>
        {translations[locale].footer_text}
      </span>
    </FooterStyles>
  );
}

export default Footer;