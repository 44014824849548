import React, {useContext, useMemo, useState} from 'react';
import ArrowDownIcon from '../../assets/icons/ArrowDownIcon';
import {LangContext} from '../../context/LangContext/LangContext';
import OutsideAlerter from "../../utils/detectClickOutside";
import {ChangeLanguageStyles} from './ChangeLanguageStyles';
import {translations} from "../../constants";

const languages: {[key: string] : string} = {
  en: 'en',
  uk: 'uk'
}

export default function ChangeLanguage() {
  const {locale, setLocale} = useContext(LangContext)
  const [menuOpen, setMenuOpen] = useState<boolean>(false)

  const lang = useMemo(() => {
    if (locale === 'en') {
      return  "EN"
    }
  
    if (locale === 'uk') {
      return  "UA"
    }
  }, [locale])

  return (
    <OutsideAlerter onClose={() => setMenuOpen(false)}>
      <ChangeLanguageStyles>
        <div className="language">
          <button
            title={translations[locale].title_language}
            className="language__button"
            onClick={() => setMenuOpen(!menuOpen)}
          >
          <span className="language__button-word">
            {lang}
          </span>
            <div className={menuOpen ? 'active' : ''}>
              <ArrowDownIcon />
            </div>
          </button>
          {menuOpen &&
              <ul className="language__list">
                  <li>
                      <button
                          title={translations[locale].title_language}
                          className="language__buttonInList"
                          onClick={() => {
                            setLocale('en')
                            localStorage.setItem('locale', 'en')
                            setMenuOpen(false)
                          }}
                      >
                          EN
                      </button>
                  </li>

                  <li>
                      <button
                          title={translations[locale].title_language}
                          className="language__buttonInList"
                          onClick={() => {
                            setLocale('uk')
                            localStorage.setItem('locale', 'uk')
                            setMenuOpen(false)
                          }}
                      >
                          UA
                      </button>
                  </li>
              </ul>
          }
        </div>
      </ChangeLanguageStyles>
    </OutsideAlerter>
  );
}
