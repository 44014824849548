import React, {useContext, useEffect, useState} from 'react';
import {RequisitesStyles} from './RequisitesStyles';
import {currencyIcons, requisites_card, requisites_fond, translations} from "../../constants";
import card from '../../assets/icons/bankCard.png';
import {LangContext} from "../../context/LangContext/LangContext";
import {Api} from "../../api";
import IndividualCard from "../../entities/IndividualCard";
import CharitableCards from "../../entities/CharitableCards";

function Requisites() {
  const { locale, setLocale } = useContext(LangContext);

  const [individualCards, setIndividualCards] = useState<IndividualCard[]>([]);
  const [charitableCards, setCharitableCards] = useState<CharitableCards[]>([]);

  useEffect(() => {
    Api.getIndividualCards()
      .then((res) => {
        setIndividualCards(res);
      })

    Api.getCharitableAccounts()
      .then((res) => {
        setCharitableCards(res);
      })
  }, [])

  return (
    <RequisitesStyles id="requisites">
      <h2>{translations[locale].requisites_title}</h2>
      <div className="cardListContainer">
        <p className="listTitle">{translations[locale].requisites_card_title}</p>
        <ul>
          {
            individualCards.map((item, index) => {
              return (
                <li key={index}>
                  <div className="imagesContainer">
                    <div className="image">
                      {currencyIcons[item.currency] && <img src={currencyIcons[item.currency]} alt={item.currency}/>}
                    </div>
                    <img src={card} alt="bank card"/>
                  </div>
                  <div className="requisitesContainer">
                    {item.cardNumber && <span className="cardNumber" onClick={() => navigator.clipboard.writeText(item.cardNumber.split('-').join(''))} title={translations[locale].title_copied}>{item.cardNumber}</span>}
                    {item.name[locale] && <span onClick={() => navigator.clipboard.writeText(item.name[locale])} title={translations[locale].title_copied}>{item.name[locale]}</span>}
                    {item.iban && (
                      <div title={translations[locale].title_copied} onClick={() => navigator.clipboard.writeText(item.iban)}>
                        <span>{translations[locale].requisites_iban}</span>
                        <span >{item.iban}</span>
                      </div>
                    )}
                    {item.tin && (
                      <div onClick={() => navigator.clipboard.writeText(item.tin)} title={translations[locale].title_copied}>
                        <span>{translations[locale].requisites_identNumb}</span>
                        <span>{item.tin}</span>
                      </div>
                    )}
                    <div onClick={() => navigator.clipboard.writeText(translations[locale].requisites_purpose2)}>
                      <span>{translations[locale].requisites_purpose}</span>
                      <span>{translations[locale].requisites_purpose2}</span>
                    </div>
                  </div>
                </li>
              )
            })
          }
        </ul>
      </div>

      <div className="cardListContainer">
        <p className="listTitle">{translations[locale].requisites_charitable_title}</p>
        <ul>
          {
            charitableCards.map((item, index) => {
              return (
                <li key={index}>
                  <div className="imagesContainer">
                    {currencyIcons[item.currency] && <img src={currencyIcons[item.currency]} alt={item.currency}/>}
                  </div>

                  <div className="requisitesContainer">
                    {item.recipientCode && (
                      <div title={translations[locale].title_copied} onClick={() => navigator.clipboard.writeText(item.recipientCode!)}>
                        <span>{translations[locale].requisites_code}</span>
                        <span >{item.recipientCode}</span>
                      </div>
                    )}

                    <span title={translations[locale].title_copied} onClick={() => navigator.clipboard.writeText(translations[locale].requisites_bo)}>{translations[locale].requisites_bo}</span>

                    {item.iban && (
                      <div title={translations[locale].title_copied} onClick={() => navigator.clipboard.writeText(item.iban)}>
                        <span>{translations[locale].requisites_iban}</span>
                        <span >{item.iban}</span>
                      </div>
                    )}

                    {item.swift && (
                      <div title={translations[locale].title_copied} onClick={() => navigator.clipboard.writeText(item.swift!)}>
                        <span>{translations[locale].requisites_swift}</span>
                        <span>{item.swift}</span>
                      </div>
                    )}
                    <div onClick={() => navigator.clipboard.writeText(translations[locale].requisites_purpose2)}>
                      <span>{translations[locale].requisites_purpose}</span>
                      <span>{translations[locale].requisites_purpose2}</span>
                    </div>
                  </div>
                </li>
              )
            })
          }
        </ul>
      </div>
    </RequisitesStyles>
  );
}

export default Requisites;