import styled from 'styled-components';

export const FooterStyles = styled.footer`
  background: #000000;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Myriad Pro", sans-serif;

  span {
    color: #fff;
    font-size: 14px;
  }
`;
