import React, {useContext, useEffect, useState} from 'react';
import {ContactsStyles} from './ContactsStyles';
import map from '../../assets/images/mapUA.png'
import {translations} from "../../constants";
import {LangContext} from "../../context/LangContext/LangContext";
import signature from '../../assets/icons/podpis.png';
import {Api} from "../../api";
import contactsFromAdmin from '../../entities/Contacts';

function Contacts() {
  const { locale, setLocale } = useContext(LangContext);
  const [contacts, setContacts] = useState<contactsFromAdmin>()

  useEffect(() => {
    Api.getContacts()
      .then((res) => {
        setContacts(res[0])
      })
  }, [])

  const getPhoneNumber = (phone: string) => {
    let number = '';

    for (const char of phone) {
      if (+char || char === '0' || char === '+') {
        number += char
      }
    }

    return number
  }

  return (
    <ContactsStyles id="contacts">
      {/*<div className="signature">*/}
      {/*  <img src={signature} alt="siganure" />*/}
      {/*</div>*/}
      <div className="mapContainer">
        <img src={map} alt="map of Ukraine"/>
        { contacts &&
          <div className="contactsWrapper">
            <a href={`mailto:${contacts?.email}`} className="email" title={translations[locale].title_email}>{contacts?.email}</a>

            <div className="workingTime">
              <span>{translations[locale].contacts_workingDays}</span>
              <span>10:00 - 17:00</span>
            </div>

            <a href={`tel:${getPhoneNumber(contacts?.phone)}`} className="phone" title={translations[locale].title_call}>{contacts?.phone}</a>

            <span className="address">{contacts?.address[locale]}</span>

            <h2 className="year">2022</h2>
          </div>
        }
      </div>
    </ContactsStyles>
  );
}

export default Contacts;