import styled from 'styled-components';

export const AboutUsStyles = styled.div`
  max-width: 647px;
  box-sizing: border-box;
  padding: 0 10px;
  width: 100%;
  margin: auto;
  padding-top: 54px;

  h2 {
    color: #ffd800;
    font-family: "Myriad Pro", sans-serif;
    font-size: 31px;
    font-weight: 700;
    line-height: 16px;
    margin-bottom: 24px;
  }

  p {
    color: #212121;
    font-family: "Arial MT", sans-serif;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.4px;
  }

  div {
    display: flex;
    justify-content: flex-end;

    img {
      object-position: center;
    }
  }
`;
