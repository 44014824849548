import styled from 'styled-components';

export const ContactsStyles = styled.div`
  max-width: 654px;
  width: 100%;
  margin: auto;
  height: 100%;
  padding-top: 54px;
  
  .signature {
    display: flex;
    justify-content: flex-end;
  }
  
  .mapContainer {
    max-width: 654px;
    height: 500px;
    width: 100%;
    position: relative;

    @media (max-width: 619px) {
      height: 450px;
    }

    @media (max-width: 559px) {
      height: 400px;
    }

    @media (max-width: 485px) {
      height: 340px;
    }

    @media (max-width: 415px) {
      height: 290px;
    }

    @media (max-width: 360px) {
      height: 260px;
    }
    
    img {
      position: absolute;
      width: 100%;
      height: auto;
      z-index: -1;
    }
    
    .contactsWrapper {
      z-index: 1;
      
      .email {
        color: #ffffff;
        font-family: "Myriad Pro", sans-serif;
        font-size: 25px;
        line-height: 16px;
        letter-spacing: 0.63px;
        
        position: absolute;
        left: 120px;
        top: 80px;
        
        @media (max-width: 642px) {
          font-size: 15px;
          letter-spacing: 0.63px;
          left: 120px;
          top: 80px;
        }

        @media (max-width: 534px) {
          left: 100px;
          top: 70px;
        }

        @media (max-width: 534px) {
          font-size: 12px;
          left: 80px;
          top: 60px;
        }

        @media (max-width: 400px) {
          font-size: 10px;
          left: 60px;
          top: 50px;
        }

        @media (max-width: 375px) {
          top: 40px;
          left: 50px;
        }
      }
      
      .workingTime {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;

        color: #ffffff;
        font-family: "Myriad Pro", sans-serif;
        font-size: 25px;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: 0.63px;

        position: absolute;
        left: 250px;
        top: 150px;

        @media (max-width: 642px) {
          font-size: 20px;
          letter-spacing: 0.63px;
        }

        @media (max-width: 581px) {
          top: 130px;
          left: 230px;
        }

        @media (max-width: 487px) {
          top: 100px;
          left: 200px;
        }

        @media (max-width: 440px) {
          top: 80px;
          left: 180px;
          font-size: 15px;
        }

        @media (max-width: 372px) {
          top: 50px;
          left: 150px;
        }
      }

      .phone {
        color: #ffd800;
        font-family: "Myriad Pro", sans-serif;
        font-size: 36px;
        font-weight: 700;
        line-height: 16px;
        text-align: center;
        letter-spacing: 0.9px;

        position: absolute;
        left: 280px;
        top: 200px;

        @media (max-width: 642px) {
          font-size: 20px;
          letter-spacing: 0.63px;
        }

        @media (max-width: 581px) {
          top: 180px;
          left: 260px;
        }

        @media (max-width: 487px) {
          top: 150px;
          left: 230px;
        }

        @media (max-width: 440px) {
          top: 120px;
          left: 210px;
          font-size: 15px;
        }

        @media (max-width: 372px) {
          top: 90px;
          left: 170px;
        }
      }
      
      .address {
        color: #000000;
        font-family: "Myriad Pro", sans-serif;
        font-size: 18px;
        font-weight: 700;
        line-height: 16px;
        text-align: center;
        letter-spacing: 0.35px;

        position: absolute;
        left: 320px;
        top: 270px;

        @media (max-width: 642px) {
          left: 280px;
          top: 240px;
        }
        
        @media (max-width: 582px) {
          left: 240px;
          top: 210px;
          font-size: 14px;
        }

        @media (max-width: 500px) {
          font-size: 12px;
        }

        @media (max-width: 484px) {
          top: 190px;
          left: 220px;
        }

        @media (max-width: 446px) {
          top: 170px;
          left: 190px;
        }

        @media (max-width: 404px) {
          left: 180px;
          top: 150px;
        }

        @media (max-width: 382px) {
          font-size: 10px;
          left: 170px;
        }

        @media (max-width: 362px) {
          top: 130px;
        }

        @media (max-width: 362px) {
          left: 140px;
          top: 125px;
        }
      }
      
      .year {
        color: #e6e9eb;
        font-family: "Myriad Pro", sans-serif;
        font-size: 100px;
        font-weight: 700;
        letter-spacing: -5.5px;
        
        position: absolute;
        top: 250px;

        @media (max-width: 562px) {
          top: 200px;
        }

        @media (max-width: 534px) {
          font-size: 70px;
        }

        @media (max-width: 454px) {
          top: 170px;
          left: 10px;
        }

        @media (max-width: 400px) {
          top: 140px;
          font-size: 50px;
        }
      }
    }
  }
`;
