import styled from 'styled-components';

export const NavigationStyles = styled.nav`
  list-style: none;
  position: sticky;
  top: 0;
  background: #ffd800;
  
  .navWrapper {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    max-width: 625px;
    padding: 0 10px;
    box-sizing: border-box;
    width: 100%;

    @media (max-width: 600px) {
      display: none;
    }
  }
  
  .navItem {
    border: none;
    //border-radius: 5px 5px 0 0;
    cursor: pointer;
    padding: 13px;
    color: #ffffff;
    font-family: "Arial MT", sans-serif;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.3px;
    box-sizing: border-box;
    display: flex;
    transition: 0.3s all;

    &:hover {
      color: #4f8ad0;
    }
  }
  
  .item {
    box-sizing: border-box;
  }
  
  button {
    background: none;
  }
  
  .active {
    background: #ffffff;
    color: #4f8ad0;
  } 
  
  .mobileMenu {
    display: none;
    position: relative;
    width: fit-content;
    margin: auto;

    @media (max-width: 600px) {
      display: flex;
      justify-content: center;
    }

    .button {
      border: none;
      background: none;
      padding: 0;
    }
    
    ul {
      list-style: none;
      position: absolute;
      top: 31px;
      left: 50%;
      padding: 10px 0;
      transform: translate(-50%, 0);
      background: #ffd800;
      border-radius: 5px;
      width: 100vw;
      
      .navItemMobile {
        cursor: pointer;
        padding: 0;
        color: #ffffff;
        font-family: "Arial MT", sans-serif;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 0.3px;
        box-sizing: border-box;
        border: none;
      }
      
      li {
        padding: 0 20px;
        text-align: center;
        
        &:not(:last-child) {
          margin-bottom: 10px;
        }
        
        a {
          color: #fff;
        }
      }
    }
  }
`;
