import React, {useContext, useEffect, useState} from 'react';
import {ProjectsStyles} from './ProjectsStyles';
import {translations} from "../../constants";
import {useNavigate} from "react-router-dom";
import {Api} from "../../api";
import Post from "../../entities/Post";
import {LangContext} from "../../context/LangContext/LangContext";


function Projects() {
  const navigate = useNavigate();
  const [projects, setProjects] = useState<Post[]>()
  const { locale, setLocale } = useContext(LangContext);

  useEffect(() => {
    Api.getPosts()
      .then((res) => {
        setProjects(res)
      })
  }, [])

  return (
    <ProjectsStyles id="projects">
      {projects?.length && <h2>{translations[locale].projects_title}</h2>}
      { projects  &&
        projects.map((item, index) => {
          return (
            <div key={index} className="photoWrapper" onClick={() => navigate(`/project/${item.slug.current}`)} title={translations[locale].title_goToProject}>
              <img src={item.mainImage[locale] ? item.mainImage[locale] : item.mainImage.en} alt="project photo"/>
            </div>
          )
        })
      }
    </ProjectsStyles>
  );
}

export default Projects;