import {PostConstants, PostKeys} from './entities/Post';
import {ObjectConstants, ObjectKeys} from './entities/CommonObjects';

const debug: boolean = true;

type TypeFilterVariables = {type: string};

function _equals(key: string, variable: string) {
  return `${key} == ${variable}`;
}

function _typeFilter(keys: ObjectKeys, variables: TypeFilterVariables) {
  let query = _equals(keys._type, variables.type);
  const draftsFilter = `(${keys._id} in path("drafts.**"))`;
  query += ` && !${draftsFilter}`;

  return query;
}

function _typeFilterParams(variables: TypeFilterVariables, constants: ObjectConstants) {
  return {[variables.type]: constants.type};
}

// function _keys(...keys: string[]) {
//   return keys.join(',');
// }

function log(object: {query?: string; params?: any}) {
  if (!debug) return;
}

const tfv: TypeFilterVariables = {type: 'type'};
const [pk, pc] = [
  //
  new PostKeys(),
  new PostConstants(),
];

export function getAboutUsQuery() {
  // const lang = i18next.language.substring(0, 2);
  const query = `*[_type == "aboutus"]{
    title,
    slug,
    body
  }`;
  log({query});
  return query;
}
export function getContactsQuery() {
  // const lang = i18next.language.substring(0, 2);
  const query = `*[_type == "contacts"]{
    title,
    slug,
    email,
    phone,
    address
  }`;
  log({query});
  return query;
}

export function getIndividualCardsQuery() {
  const query = `*[_type == "individualCards"]{
    title,
    slug,
    cardNumber,
    name,
    iban,
    tin,
    currency
  }`;
  log({query});
  return query;
}

export function getCharitableFundeAccountsQuery() {
  const query = `*[_type == "charitableCards"]{
    title,
    slug,
    recipientCode,
    iban,
    swift,
    currency
  }`;
  log({query});
  return query;
}

export function getStoriesQuery() {
  // const lang = i18next.language.substring(0, 2);
  const query = `*[_type == "stories"]{
    title,
    slug,
    year,
    pictures[]->{
      body,
      picture,
      slug,
      title
    },
    videos[]->{
      body,
      video,
      slug,
      title
    },
    
  }`;
  log({query});
  return query;
}

export function getProjectsPreviewQuery(quantity?: number) {
  // const lang = i18next.language.substring(0, 2);
  const query = `*[_type == "post"]${quantity ? `[0...${quantity}]` : ''}{
    mainImage,
    slug,
  }`;
  log({query});
  return query;
}
export function getProjectByKeyQuery(postKey: string) {
  const pv = {slug: 'slug'};
  const query = `
        *[_type == 'post' && slug.current == '${postKey}']
        {
          title,
          slug,
          banner,
          body,
          collected,
          done,
          mainImage,
          gallery[]->{
            body,
            picture,
            slug,
            title,
            video,
          },
        }
    `;
  const params = {
    ..._typeFilterParams(tfv, pc),
    postKey,
  };
  log({query, params});
  return {query, params};
}


export function getPostByKeyQuery(postKey: string) {
  const pv = {slug: 'slug'};
  const query = `
        *[_type == 'post' && slug.current == '${postKey}']
        {
          title,
          slug,
          banner,
          body,
          pictures[]->{
            body,
            picture,
            slug,
            title
          },
          videos[]->{
            body,
            video,
            slug,
            title
          }
        }
    `;
  const params = {
    ..._typeFilterParams(tfv, pc),
    postKey,
  };
  log({query, params});
  return {query, params};
}
