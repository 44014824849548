import SanityClient from '../sanity/SanityClient';
import Mappers from '../entities/Mappers';
import SanityClientConfiguration from '../sanity/SanityClientConfiguration';

export default class ApiBase {
  private readonly client: SanityClient;

  protected readonly mappers: Mappers;

  constructor(config: SanityClientConfiguration) {
    this.client = new SanityClient(config);
    this.mappers = new Mappers(this.client);
  }

  public fetch<Result>(query: string, params: any = undefined): Promise<Result> {
    return this.client.fetch(query, params);
  }
}
