import React, {useContext, useState} from 'react';
import {NavigationStyles} from './NavigationStyles';
import {navItems, translations} from "../../constants";
import {useLocation, useNavigate} from "react-router-dom";
import Menu from "../../assets/icons/Menu";
import OutsideAlerter from "../../utils/detectClickOutside";
import {LangContext} from "../../context/LangContext/LangContext";
import projects from "../projects/Projects";

function Navigation() {
  let location = useLocation();
  const [dropdown, setDropdown] = useState<boolean>(false);
  const navigate = useNavigate();
  const { locale, setLocale } = useContext(LangContext);

  return (
    <NavigationStyles>
      <div className="navWrapper">
        {navItems[locale].map((item, index) => {
          if (location.pathname.length > 1 && item.href === '#projects') {
            return (
              <li className="item" key={index}>
                <button type="button" className={'navItem'} key={index} onClick={() => navigate('/#projects')}>
                  {item.text}
                </button>
              </li>
            )
          }

          if (location.pathname.length > 1 && item.href === '#main') {
            return (
              <li className="item" key={index}>
                <button type="button" className={location.hash === item.href ? 'active navItem' : 'navItem'} key={index} onClick={() => navigate('/')}>
                  {item.text}
                </button>
              </li>
            )
          }

          return (
            <li className="item" key={index}>
              <a
                className={location.hash === item.href || ((location.pathname === '/' && item.href === '#main') && !location.hash) ? 'active navItem' : 'navItem'}
                key={index}
                href={location.pathname.length > 1 && location.pathname.includes('project') && (item.href === '#contacts' || item.href === '#aboutus' || item.href === '#projects') ? `/${item.href}` : item.href}
              >
                {item.text}
              </a>
            </li>
          )
        })}
      </div>
      <div className="mobileMenu">
        <OutsideAlerter onClose={setDropdown}>
          <button title={translations[locale].title_menuButton} type="button" onClick={() => setDropdown(!dropdown)} className="button">{<Menu />}</button>

          {dropdown && (
            <ul className={dropdown && 'dropdownActive'}>
              {
                navItems[locale].map((item, index) => {

                  if (location.pathname.length > 1 && item.href === '#projects') {
                    return (
                      <li className="item" key={index}>
                        <button type="button" className={'navItemMobile'} key={index} onClick={() => navigate('/#projects')}>
                          {item.text}
                        </button>
                      </li>
                    )
                  }

                  if (location.pathname.length > 1 && item.href === '#main') {
                    return (
                      <li className="item" key={index}>
                        <a type="button" className={'navItemMobile'}  key={index} onClick={() => {
                          setDropdown(false);
                          navigate('/');
                        }}>
                          {item.text}
                        </a>
                      </li>
                    )
                  }

                  return (
                    <li className="item" key={index}>
                      <a className={'navItemMobile'} key={index} href={location.pathname.length > 1 && location.pathname.includes('project') && (item.href === '#contacts' || item.href === '#aboutus' || item.href === '#projects') ? `/${item.href}` : item.href} onClick={() => setDropdown(false)}>
                        {item.text}
                      </a>
                    </li>
                  )
                })}
            </ul>
        )}
        </OutsideAlerter>
      </div>

    </NavigationStyles>
  );
}

export default Navigation;