import React from 'react';

function ArrowDownIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="8"
      fill="none"
      viewBox="0 0 12 8"
    >
      <path
        fill="#18191F"
        d="M10.293.293L6 4.586 1.707.293.293 1.707 6 7.414l5.707-5.707L10.293.293z"
      />
    </svg>
  );
}

export default ArrowDownIcon;
