import React, {useContext, useEffect, useState} from 'react';
import {AboutUsStyles} from './AboutUsStyles';
import {translations} from "../../constants";
import {Api} from "../../api";
import {PortableText} from "@portabletext/react";
import {LangContext} from "../../context/LangContext/LangContext";

function AboutUs() {
  const { locale, setLocale } = useContext(LangContext);
  const [text, setText] = useState<any>();

  useEffect(() => {
    Api.getAboutUs()
      .then((res) => {
        setText(res[0].body)
      })
  }, [])

  return (
    <AboutUsStyles id='aboutus'>
      <h2>{translations[locale].aboutus_title}</h2>

      {text &&
        <PortableText value={text[locale] ? text[locale] : text.en}/>
      }

    </AboutUsStyles>
  );
}

export default AboutUs;