import styled from 'styled-components';
import background from '../../assets/images/6-layers.png'

export const HeaderStyles = styled.header`

  .blueContainer {
    background: #3182df;
    //background-repeat: no-repeat;
    //background-position: center;
    //background-image: url(${background});


    
    .blueContent {
      box-sizing: border-box;
      padding: 24px 0 14px 0;
      max-width: 647px;
      width: 100%;
      margin: auto;
      position: relative;
      height: 248px;
      
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      .background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(${background});
        z-index: 1;
      }
        
      p {
        color: #ffffff;
        font-family: "Candara", sans-serif;
        font-size: 16px;
        line-height: 23px;
        letter-spacing: 0.8px;
        padding: 0 10px;
        text-align: center;
      }

      .logoContainer {
        max-width: 398px;
        width: 100%;

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  .yellowContainer {
    height: 206px;
    background: #ffd800;
    position: relative;
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;

    @media (max-width: 600px) {
      flex-direction: row;
      justify-content: flex-end;
    }
    
    .emblemContainer {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .emblemIcon {
      opacity: 70%;
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    
    .gradient {
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate(-60%, -45%);
    }

    .linksContainer {
      padding: 10px;

      @media (max-width: 600px) {
        padding: 5px;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        display: none;
      }
      
      .linkItem {
        width: fit-content;
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        gap: 8px;
        z-index: 1;
        color: #4f8ad0;
        font-family: "Arial MT", sans-serif;
        font-size: 19px;
        letter-spacing: 0.5px;
        line-height: normal;
      }

      .topLinks {
        margin: auto auto 31px;
        display: flex;
        justify-content: space-between;
        max-width: 627px;
        width: 100%;
        
        @media (max-width: 600px) {
          span {
            display: none;
          }
          
          flex-direction: column;
          align-items: center;
          max-width: fit-content;
          margin: auto 0 0;
          justify-content: flex-start;
        }
      }

      .bottomLinks {
        margin: auto;
        display: flex;
        justify-content: space-between;
        max-width: 480px;
        width: 100%;

        @media (max-width: 600px) {
          span {
            display: none;
          }
          
          max-width: fit-content;
          flex-direction: column-reverse;
          align-items: center;
          margin: 0;
          justify-content: flex-start;
        }
      }
      
      .linksWithoutText {
        display: flex;
        margin: auto;
      }
    }
    
    .headerLinksMobile {
      display: none;
      
      @media (max-width: 600px) {
        padding-right: 5px;
        display: flex;
        flex-direction: column;
        height: 226px;
        justify-content: space-between;
        z-index: 1;
        transform: translateY(-34px);
      }
    }
  }
`;
