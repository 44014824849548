/* eslint-disable max-classes-per-file */
import {ObjectConstants, ObjectKeys} from './CommonObjects';
import Picture from "../../entities/Picture";
import Video from "../../entities/Video";

export default interface Post {
  slug: {[index: string]: string},
  title: {[index: string]: string},
  mainImage: { [index: string]: string },
  body: {[index: string]: any[]},
  gallery?: Picture[] | Video[],
  collected: {[index: string]: any[]},
  done: {[index: string]: any[]},
}

export class PostKeys extends ObjectKeys {
  title = 'title';

  body = 'body';

  slug = 'slug';

  gallery = 'gallery';

  mainImage = 'mainImage';

  collected = 'collected';

  done = 'done';

  getAll() {
    return [
      ...super.getAll(),
      this.body,
      this.title,
      this.gallery,
      this.slug,
      this.mainImage,
      this.done,
      this.collected
    ];
  }
}

export class PostConstants extends ObjectConstants {
  type = 'post';
}
