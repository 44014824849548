const getUserLanguage = () => {
  if (localStorage.getItem('locale') && localStorage.getItem('locale')?.includes('uk')) {
    return 'uk';
  }

  if (localStorage.getItem('locale') && localStorage.getItem('locale')?.includes('en')) {
    return 'en';
  }

  if (localStorage.getItem('locale') === 'uk' || localStorage.getItem('locale') === 'en') {
    return localStorage.getItem('locale')
  }

  if (
    window.navigator.language.toLowerCase().includes('uk')
    || window.navigator.language.toLowerCase().includes('ua')
  ) {
    return 'uk';
  }

  return 'en';
};

export default getUserLanguage
