import React, { CSSProperties, useRef } from 'react';
import './modal.css';

type Props = {
  extraStyles?: CSSProperties,
  children: any,
  onClose: () => void,
}

const Modal: React.FC<Props> = ({ extraStyles, children, onClose }) => {
  const ref = useRef(null);

  function handleClose(event: any) {
    if (event.target.children[0]?.classList.contains('modalBody')) {
      onClose();
    }
  }

  return (
    <div className="modal" ref={ref} onClick={(event:any) => handleClose(event)}>
      <div className="modalBody" style={extraStyles}>
        {children}
      </div>
    </div>
  );
};

export default Modal;
