import React, {useEffect, useState} from 'react';
import './App.css';
import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import Layout from "./components/layout/Layout";
import MainPage from "./pages/mainPage/MainPage";
import SelectedProject from "./components/selectedProject/SelectedProject";
import { LangContext } from './context/LangContext/LangContext';
import getUserLanguage from "./utils/getUserLanguage";
import {metaDescription, metaTitle} from "./constants";

function App() {
  const [locale, setLocale] = useState<string>('en')

  useEffect(() => {
    const language = getUserLanguage()

    if (language) {
      setLocale(language)
    }
  }, [])

  useEffect(() => {
    document.title = metaTitle[locale];
    document.querySelector('meta[name="description"]')!.setAttribute("content", metaDescription[locale]);
  }, [locale])

  return (
    <div className="App">
      <LangContext.Provider value={{
        locale,
        setLocale
      }}>
        <Routes>
          <Route element={<Layout /> }>
            <Route path="/" element={<MainPage />}/>
            <Route path="/project/:slug" element={<SelectedProject />}/>
          </Route>
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </LangContext.Provider>
    </div>
  );
}

export default App;
