import styled from 'styled-components';

export const LayoutStyles = styled.div`
  .layout {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;
