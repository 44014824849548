import React, {useEffect} from 'react';
import Projects from "../../components/projects/Projects";
import AboutUs from "../../components/aboutUs/AboutUs";
import {useLocation, useNavigate} from "react-router-dom";
import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import Contacts from "../../components/contacts/Contacts";


function MainPage() {
  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({
            behavior: "smooth",
            block: 'start',
          });
        }
      }, 0);
    }
  }, []); // do this on route change

  useEffect(() => {
    if (hash === '') {
      window.scrollTo(0, 0);
    }

    if (hash === '#contacts') {
      setTimeout(() => {
        scroller.scrollTo('contacts', {
          duration: 0,
          delay: 0,
          smooth: 'auto'
        })
      }, 200)
    }

    if (hash === '#projects') {
      setTimeout(() => {
        scroller.scrollTo('projects', {
          duration: 0,
          delay: 0,
          smooth: 'auto'
        })
      }, 200)
    }

    if (hash === '#aboutus') {
      setTimeout(() => {
        scroller.scrollTo('aboutus', {
          duration: 0,
          delay: 0,
          smooth: 'auto'
        })
      }, 200)
    }

  }, [pathname, hash, key])

  return (
    <>
      <Element name="projects">
        <Projects />
      </Element>
      <div>
      <Element name="aboutus">
        <AboutUs />
      </Element>

      <Element name="test">
        <Contacts />
      </Element>
      </div>
    </>
  );
}

export default MainPage;