import React, {useContext} from 'react';
import {HeaderStyles} from './HeaderStyles';
import logo from '../../assets/images/logo.png'
import logo2 from '../../assets/images/logo2.png'
import emblem from '../../assets/images/gerb.png'
import gradient from '../../assets/images/gradient_emblem.png'
import {headerLinks, translations} from "../../constants";
import {LangContext} from "../../context/LangContext/LangContext";
import ChangeLanguage from "../ChangeLanguage/ChangeLanguage";

function Header() {
  const { locale, setLocale } = useContext(LangContext);

  return (
    <HeaderStyles id="main">
      <div className="blueContainer">
        <div className="blueContent">
          <a className="background" href="/" />

          <a className="logoContainer" href="/">
            <img src={logo2} alt="logo"/>
          </a>
          <div className="langContainer">
            <ChangeLanguage />
          </div>
          <p>{translations[locale].header_text}</p>
        </div>
      </div>
      <div className="yellowContainer">
        <div className="emblemContainer">
          <img src={emblem} alt="emblem" className="emblemIcon"/>
          {/*<img src={gradient} alt="gradient" className="gradient"/>*/}
        </div>
        <div className="linksContainer">
            <div className="topLinks">
              {
                headerLinks.slice(0, 3).map((item, index) => {
                  return (
                    <a className="linkItem" href={item.link} target="_blank" rel="noreferrer" key={index}>
                      <img src={item.icon} alt={item.alt} />
                      <span>{item.text}</span>
                    </a>
                  )
                })
              }
            </div>
            <div className="bottomLinks">
                {
                headerLinks.slice(3).map((item, index) => {
                  return (
                    <a className="linkItem" href={item.link} target="_blank" rel="noreferrer" key={index}>
                      <img src={item.icon} alt={item.alt} />
                      <span>{item.text}</span>
                    </a>
                  )
                })
              }
            </div>
        </div>
        <div className="headerLinksMobile">
          {
            headerLinks.map((item, index) => {
              return (
                <a className="linkItem" href={item.link} target="_blank" rel="noreferrer" key={index}>
                  <img src={item.icon} alt={item.alt} />
                </a>
              )
            })
          }
        </div>
      </div>
    </HeaderStyles>
  );
}

export default Header;