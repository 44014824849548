import styled from 'styled-components';

export const RequisitesStyles = styled.div`
  max-width: 647px;
  width: 100%;
  margin: auto;
  padding: 52px 10px 0 10px;
  box-sizing: border-box;
  
  h2 {
    color: #ffd800;
    font-family: "Myriad Pro", sans-serif;
    font-size: 31px;
    font-weight: 700;
    line-height: 16px;
    text-align: center;
    margin-bottom: 20px;
    
  }
  
  .cardListContainer {
    &:not(:last-child) {
      margin-bottom: 38px;
    }
    
    .listTitle {
      text-align: center;
      color: #000000;
      font-family: "Arial MT", sans-serif;
      font-size: 14px;
      font-weight: bold;
      line-height: 13.53px;
      text-transform: uppercase;
      letter-spacing: 0.49px;
      margin-bottom: 12px;
    }
    
    ul {
      list-style: none;
      
      li {
        display: flex;
        padding: 16px 24px;
        background: #f4f4f4;
        
        &:not(:last-child) {
          margin-bottom: 11px;
        }
        
        .imagesContainer {
          margin-right: 52px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 13px;
          
          @media (max-width: 400px) {
            margin-right: 30px;
          }
        }
        
        .requisitesContainer {
          display: flex;
          flex-direction: column;
          gap: 8px;
          
          div, span {
            cursor: pointer;
          }
        }
        
        span {
          color: #000000;
          font-family: "Arial MT", sans-serif;
          font-size: 12px;
          font-weight: 400;
          letter-spacing: 0.84px;
        }
        
        .cardNumber {
          font-size: 14px;
          font-weight: bold;
          letter-spacing: 0.98px;
        }
      }
    }
  }
`;
