import styled from 'styled-components';

export const ChangeLanguageStyles = styled.div`
  .language__button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    border: none;
    cursor: pointer;
    
    div {
        transition: 0.3s ease;
    }
    
    .active {
      transform: rotate(180deg);
    }
  }
  
  .language {
    position: absolute;
    right: 5px;
    top: 14px;
    width: 40px;
    z-index: 10;
  }

  .language__list {
    position: absolute;
    z-index: 99;
    list-style: none;
  }

    .language__button {
      width: 40px;
      height: 33px;
      font-weight: 600;
      background-color: #F4F5F7;
      border-radius: 4px;
      outline: none;
      
    }

  .language__buttonInList {
    width: 40px;
    height: 28px;
    font-weight: 600;
    background: none;
    border: none;
    color: #FFF;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    
    @media (max-width: 463px) {
      background-color: #F4F5F7;
      color: #000;
    }
  }
  
  .language_button-word {
    margin-right: 8px;
  }

`;
