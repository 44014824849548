// eslint-disable-next-line max-classes-per-file
export interface ImageObject {
  asset: {
    _ref: string;
  };
}

export interface TrackObject {
  asset: {
    _ref: string;
  };
}

export abstract class ObjectKeys {
  readonly _id = '_id';

  readonly _type = '_type';

  getAll(): string[] {
    return [this._id];
  }

  localedKey(key: string, locale: string, fallbackLocale: string = 'en') {
    return `"${key}": coalesce(${key}.${locale}, ${key}.${fallbackLocale})`;
  }
}

export abstract class ObjectConstants {
  abstract type: string;
}
