import {IApi} from './IApi';
import {
  getAboutUsQuery,
  getCharitableFundeAccountsQuery,
  getContactsQuery,
  getIndividualCardsQuery,
  getProjectByKeyQuery,
  getProjectsPreviewQuery
} from './QueryBuilder';
import ApiBase from './base/ApiBase';
import ApiPost from './entities/Post';
import ApiAboutUs from './entities/AboutUs';
import ApiContacts from './entities/Contacts';
import ApiCharitableCards from "./entities/ApiCharitableCards";
import ApiIndividualCard from "./entities/ApiIndividualCard";

export default class Api extends ApiBase implements IApi {
  public async getPosts(quantity?: number) {
    const query = getProjectsPreviewQuery(quantity);
    const post = await this.fetch<ApiPost[]>(query);
    return this.mappers.mapFromApiPosts(post);
  }

  public async getAboutUs() {
    const query = getAboutUsQuery();
    const story = await this.fetch<ApiAboutUs[]>(query);
    return this.mappers.mapFromApiAboutUs(story);
  }

  public async getContacts() {
    const query = getContactsQuery();
    const contacts = await this.fetch<ApiContacts[]>(query);
    return this.mappers.mapFromApiContacts(contacts);
  }

  public async getCharitableAccounts() {
    const query = getCharitableFundeAccountsQuery();
    const charitableCards = await this.fetch<ApiCharitableCards[]>(query);
    return this.mappers.mapFromApiCharitableCards(charitableCards);
  }

  public async getIndividualCards() {
    const query = getIndividualCardsQuery();
    const charitableCards = await this.fetch<ApiIndividualCard[]>(query);
    return this.mappers.mapFromApiIndividualCards(charitableCards);
  }

  public async getPostByKey(
    documentKey: string,
  ) {
    const {query, params} = getProjectByKeyQuery(documentKey);
    const post = await this.fetch<ApiPost[]>(query, params).then<
      ApiPost | undefined
    >((response) => (response.length > 0 ? response[0] : undefined));
    if (!post) throw new Error('Post not found');
    return this.mappers.mapFromApiPost(post);
  }
}
